<template>
  <div class="host">
    <Form ref="form" v-slot="observer">
      <Field
        name="present"
        label="プレゼント"
        rules="required"
        v-slot="{ field, meta }"
        v-model="data.present"
        as="div"
      >
        <div class="input-group" :class="{ ok: meta.valid }">
          <label><span class="status"></span>豪華景品プレゼント</label>
          <ul class="input-errors" v-if="observer.errors.present">
            <li>{{ observer.errors.present }}</li>
          </ul>
          <select
            name="present"
            :class="{ ng: meta.touched && !meta.valid }"
            v-bind="field"
          >
            <option value="">選択してください</option>
            <option
              v-for="present in select.presents"
              :value="present"
              :key="present"
            >
              {{ present }}
            </option>
          </select>
        </div>
      </Field>
      <div class="input-groups">
        <div class="input-groups-title">
          プレゼント発送先情報をご記入ください
        </div>
        <div
          class="input-group"
          :class="{
            ok:
              data.sei &&
              !observer.errors.sei &&
              data.mei &&
              !observer.errors.mei,
          }"
        >
          <label><span class="status"></span>お名前</label>
          <ul
            class="input-errors"
            v-if="observer.errors.sei || observer.errors.mei"
          >
            <li v-if="observer.errors.sei">{{ observer.errors.sei }}</li>
            <li v-if="observer.errors.mei">{{ observer.errors.mei }}</li>
          </ul>
          <div class="input-inline">
            <text-input
              vid="sei"
              type="text"
              name="sei"
              label="お名前（姓）"
              :rules="{
                required: true,
                dependency_string: true,
                max: 16,
                regex: /^([^a-zA-Z0-9ｧ-ﾝﾞﾟ!-/:-@¥[-`{-~]+)$/,
              }"
              v-model="data.sei"
              placeholder="例）佐藤"
            ></text-input>
            <text-input
              vid="mei"
              type="text"
              name="mei"
              label="お名前（名）"
              :rules="{
                required: true,
                dependency_string: true,
                max: 16,
                regex: /^([^a-zA-Z0-9ｧ-ﾝﾞﾟ!-/:-@¥[-`{-~]+)$/,
              }"
              v-model="data.mei"
              placeholder="例）愛"
            ></text-input>
          </div>
        </div>
        <div
          class="input-group"
          :class="{
            ok:
              data.sei_katakana &&
              !observer.errors.sei_katakana &&
              data.mei_katakana &&
              !observer.errors.mei_katakana,
          }"
        >
          <label><span class="status"></span>フリガナ</label>
          <ul
            class="input-errors"
            v-if="observer.errors.sei_katakana || observer.errors.mei_katakana"
          >
            <li v-if="observer.errors.sei_katakana">
              {{ observer.errors.sei_katakana }}
            </li>
            <li v-if="observer.errors.mei_katakana">
              {{ observer.errors.mei_katakana }}
            </li>
          </ul>
          <div class="input-inline">
            <text-input
              vid="sei_katakana"
              type="text"
              name="sei_katakana"
              label="お名前（セイ）"
              :rules="{ required: true, max: 16, regex: /^[ァ-ヶー]*$/ }"
              v-model="data.sei_katakana"
              placeholder="例）サトウ"
              @blur="changeKana"
            ></text-input>
            <text-input
              vid="mei_katakana"
              type="text"
              name="mei_katakana"
              label="お名前（メイ）"
              :rules="{ required: true, max: 16, regex: /^[ァ-ヶー]*$/ }"
              v-model="data.mei_katakana"
              placeholder="例）アイ"
              @blur="changeKana"
            ></text-input>
          </div>
        </div>
        <text-input
          type="tel"
          name="birthday"
          label="ご本人様の生年月日"
          rules="required|date_format:YYYYMMDD|age"
          v-model="data.birthday"
          placeholder="例）1988年8月20日→19880820"
        ></text-input>
        <text-input
          type="tel"
          name="postal"
          label="郵便番号"
          :rules="{ required: true, regex: /^\d{7}$/ }"
          v-model="data.postal"
          placeholder="例）1050011"
          @keyup="getAddr($event)"
        >
          <div class="annotation">※ハイフンはつけないでください</div>
        </text-input>
        <text-input
          type="text"
          name="pref"
          label="都道府県"
          rules="required|prefecture"
          v-model="data.pref"
          placeholder="自動入力"
          :readonly="isLockedPref"
        >
          <div class="annotation">※郵便番号から自動入力されます</div>
        </text-input>
        <text-input
          type="text"
          name="addr1"
          label="市区町村"
          rules="required"
          v-model="data.addr1"
          placeholder="自動入力"
        >
          <div class="annotation">※郵便番号から自動入力されます</div>
        </text-input>
        <text-input
          type="text"
          name="addr2"
          label="番地"
          :rules="{
            required: true,
            valid_string_or_empty: true,
            max: 32,
            regex: /^([^ｧ-ﾝﾞﾟ!-,:-@¥[-`{-~]+)$/,
          }"
          v-model="data.addr2"
          placeholder="例）2-11-11"
        ></text-input>
        <text-input
          type="text"
          name="addr3"
          label="マンション名・部屋番号"
          :rules="{
            valid_string_or_empty: true,
            max: 128,
          }"
          v-model="data.addr3"
          placeholder="例）芝マンション301"
        >
          <div class="annotation">
            ※苗字と違う場合のみ◯◯様方とご記入ください<br />
            ※マンション・建物名がある場合は必ず部屋番号までご記入ください
          </div>
        </text-input>
        <text-input
          type="tel"
          name="tel"
          label="ご連絡可能な電話番号"
          :rules="{
            required: true,
            regex: /^(0[5789]0[0-9]{8}|0[1-9][1-9][0-9]{7})$/,
          }"
          v-model.lazy="data.tel"
          placeholder="例）09001234567"
        >
          <div class="annotation">
            ※ハイフンはつけないでください<br />
            <span class="alert"
              >※応募者さまご本人の電話番号をご入力ください<br />&nbsp;&nbsp;プレゼントお届けについてご連絡する場合があります</span
            >
          </div>
        </text-input>
        <div
          class="input-group"
          :class="{
            ok:
              multiple.contact_times.length > 0 &&
              !observer.errors.contact_times,
          }"
        >
          <label><span class="status"></span>つながりやすい時間帯</label>
          <ul class="input-errors" v-if="observer.errors.contact_times">
            <li>{{ observer.errors.contact_times }}</li>
          </ul>
          <div class="checkbox">
            <label v-for="time in select.contact_times" :key="time"
              ><Field
                name="contact_times"
                label="つながりやすい時間帯"
                type="checkbox"
                rules="required"
                v-model="multiple.contact_times"
                :value="time"
              ></Field
              ><i></i>{{ time }}</label
            >
          </div>
        </div>
      </div>
      <div class="btn-area">
        <img
          src="https://mamab.jp/uploads/web_image/btn_next.png"
          width="75%"
          @click="next"
          class="pointer"
          :class="{ disabled: isConnectingStep2 }"
        />
      </div>
    </Form>
  </div>
</template>

<style lang="scss" scoped>
.disabled {
  cursor: not-allowed;
  opacity: 0.6;
}
</style>

<script>
import Yubinbango from "@/yubinbango-core";
import MarketingCloud from "@/components/MarketingCloud.vue";
import { scroll } from "@/util/util";
export default {
  name: "Step1",
  mixins: [MarketingCloud],
  data() {
    return {
      isConnectingStep2: false,
      isLockedPref: true,
      multiple: {
        contact_times: [],
      },
      data: {
        present: "",
        sei: "",
        mei: "",
        sei_katakana: "",
        mei_katakana: "",
        birthday: "",
        postal: "",
        pref: "",
        addr1: "",
        addr2: "",
        addr3: "",
        email: "",
        tel: "",
        contact_time: "",
      },
      select: {
        presents: [
          "タニタ 手首式血圧計",
          "BRAUN 電動歯ブラシ",
          "スマートウォッチ ファーウェイ BAND9",
          "ドクターエア エクサガン ケア",
          "デイリートート",
          "くるっと保冷温バッグ",
        ],
        contact_times: ["午前中", "12時～15時", "15時～18時", "18時～21時"],
      },
    };
  },
  created() {
    this.data = {
      ...this.data,
      ...this.$store.state.answer,
    };
    this.multiple.contact_times = this.data.contact_time
      ? this.data.contact_time.split(",")
      : [];
  },
  methods: {
    next() {
      if (this.isConnectingStep2) {
        return;
      }
      this.isConnectingStep2 = true;
      // トラッキング(MarketingCloud)
      this.mcStep1Next();

      this.$refs.form.validate().then((result) => {
        if (result.valid) {
          this.data.contact_time = this.multiple.contact_times
            .filter((str) => str !== "")
            .join(",");
          this.$store.dispatch("answer/answer", this.data);
          this.$router.push("step2");
        } else {
          this.isConnectingStep2 = false;
          this.$nextTick(() => {
            scroll(".input-errors");
          });
        }
      });
    },
    changeKana() {
      const self = this;

      ["sei_katakana", "mei_katakana"].forEach(function (key) {
        if (self.data[key]) {
          self.data[key] = self.data[key].replace(
            /[\u3041-\u3096]/g,
            function (match) {
              return String.fromCharCode(match.charCodeAt(0) + 0x60);
            }
          );
        }
      });
    },
    getAddr(event) {
      const postcode = event.target.value;
      if (postcode.length !== 7) {
        return;
      }

      new Yubinbango(postcode, (addr) => {
        this.data.postal = postcode;
        this.data.pref = addr.region;
        this.data.addr1 = addr.locality + addr.street;

        this.isLockedPref = ![4980000, 6180000, 8710000].includes(
          Number(postcode)
        );
        // Vee-Validate4版の個別
        ["postal", "pref", "addr1"].forEach((key) => {
          this.$refs.form.validateField(key);
        });
      });
    },
  },
};
</script>
